<template>
    <div class="Community">
        <!-- 轮播图 -->
        <div class="f-c-c">
            <el-carousel class="carousel" height="400px">
                <el-carousel-item v-for="(item, index) of swiperList" :key="index">
                    <el-image style="width: 100%; height: 100%;" :src="item.adUrl" fit="fill"></el-image>
                </el-carousel-item>
            </el-carousel>
        </div>
        <div class="breadcrumb">
            <el-breadcrumb class="f-c-e" separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path: '/' }">
                    <img style="width: 16px;height: 14px;" src="@/assets/home.png" alt="">
                    <span> 当前位置： 首页</span>
                </el-breadcrumb-item>
                <el-breadcrumb-item>社区</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="taskList f-c-b mt-20">
            <img src="@/assets/Community/Index/IndustryTrends.png" alt="" @click="handleNavBoxItem('IndustryTrends')">
            <img src="@/assets/Community/Index/productSupply.png" alt="" @click="handleNavBoxItem('ProductExchange')">
            <img src="@/assets/Community/Index/discussion.png" alt="" @click="handleNavBoxItem('ShiLiaoZaTan')">
            <img src="@/assets/Community/Index/find.png" alt="" @click="handleNavBoxItem('SupplyAndPurchase')">
            <img src="@/assets/Community/Index/recruit.png" alt="" @click="handleNavBoxItem('TalentRecruitment')">
            <img src="@/assets/Community/Index/advice.png" alt="" @click="handleNavBoxItem('SuggestedFeedback')">
        </div>
        <div class="SupplyAndPurchaseList mt-80">
            <div class="title mb-50">供应求购</div>
            <div class="f-w">
                <div class="item" v-for="(item, index) of SupplyAndPurchaseList" :key="index"
                    @click="toSupplyAndPurchase(item.postId)">
                    <img v-if="item.mobject1" :src="item.mobject1" alt="">
                    <img v-else src="@/assets/Community/Index/moren.png" alt="">
                    <div class="postHead mt-22">{{ item.postHead }}</div>
                    <div class="previewContent">{{ item.previewContent }}</div>
                </div>
            </div>
        </div>
        <div class="ProductExchange mt-80">
            <div class="title mb-50">产品交流</div>
            <div class="f-w">
                <div class="item" v-for="(item, index) of ProductExchange" :key="index"
                    @click="toProductExchange(item.postId)">
                    <img v-if="item.mobject1" :src="item.mobject1" alt="">
                    <img v-else src="@/assets/Community/Index/moren.png" alt="">
                    <div class="postHead mt-22">{{ item.postHead }}</div>
                    <div class="previewContent">{{ item.previewContent }}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'CommunityIndex',
    data() {
        return {
            swiperList: [],//轮播
            ProductExchange: [],//产品交流
            SupplyAndPurchaseList: [],//供应求购
            NavBox: [{
                title: '行业动态',
                name: 'IndustryTrends'
            }, {
                title: '产品供应',
                name: 'SupplyAndPurchase'
            }, {
                title: '产品交流',
                name: 'ProductExchange'
            }, {
                title: '人才招聘',
                name: 'TalentRecruitment'
            }, {
                title: '识料杂谈',
                name: 'ShiLiaoZaTan'
            }, {
                title: '建议反馈',
                name: 'SuggestedFeedback'
            }, {
                title: '我的求购',
                name: 'MyPurchase'
            }]
        }
    },
    async created() {
        this.frontPageCarouselList();
        this.forumArticleList();
        this.forumBuySearchList();
        this.getIpAddress();
    },
    methods: {
        // 获取轮播信息
        frontPageCarouselList() {
            this.$http.frontPageCarouselList({}).then(res => {
                if (res.code == 200) {
                    this.swiperList = res.data
                }
            })
        },
        // 点击导航盒子
        handleNavBoxItem(value) {
            if (value == 'SuggestedFeedback') {
                return this.$common.message('暂未开放此功能！', 'warning')
            }
            if (value == 'TalentRecruitment') {
                this.$router.push('/TalentRecruitmentIndex')
            } else if (value == 'IndustryTrends') {
                this.$router.push('/IndustryTrendsList')
            }else if(value == 'ProductExchange'){
                this.$router.push('/productCommunList')
            }else if( value == 'ShiLiaoZaTan' ){
                this.$router.push('/MiscellaneousTalkList')
            }else if( value == 'SupplyAndPurchase'){
                this.$router.push('/LookMaterialsList')
            }
            else {
                this.$router.push({
                    name: 'PublishableList', query: {
                        key: this.$UrlEncode.encode(JSON.stringify({
                            type: value
                        }))
                    }
                })
            }

        },
        // 产品交流列表
        forumArticleList() {
            let that = this;
            that.$http.forumArticleList({
                postUserId: parseInt(that.$store.state.userId),
                token: that.$getStorage('token'),
                postHead: '',
                page: 1,
                size: 10
            }).then((res) => {
                if (res.code == 200) {
                    res.data.records.forEach(item => {
                        if (item.mobject1) {
                            let imgIndex = item.mobject1.lastIndexOf("."),//获取分隔符最后出现的位置
                                ext = item.mobject1.substr(imgIndex + 1);//从分隔符位置开始截取，得到的是如：jpg，png，jpeg等
                            item.mobject1Type = ext
                        }
                    })
                    this.ProductExchange = res.data.records
                }
            })
        },
        //  供应求购帖子搜索列表接口
        forumBuySearchList() {
            let that = this;
            that.$http.forumBuySearchList({
                postUserId: parseInt(that.$store.state.userId),
                token: that.$getStorage('token'),
                postHead: '',
                page: 1,
                size: 10
            }).then(res => {
                if (res.code == 200) {
                    res.data.records.forEach(item => {
                        if (item.mobject1) {
                            let imgIndex = item.mobject1.lastIndexOf("."),//获取分隔符最后出现的位置
                                ext = item.mobject1.substr(imgIndex + 1);//从分隔符位置开始截取，得到的是如：jpg，png，jpeg等
                            item.mobject1Type = ext
                        }
                    });
                    this.SupplyAndPurchaseList = res.data.records;

                }
            })
        },
        // 供应求购
        toSupplyAndPurchase(id) {
            this.$router.push({
                name: 'SupplyAndPurchaseDetail', query: {
                    key: this.$UrlEncode.encode(JSON.stringify({
                        id: id,
                        type: 'SupplyAndPurchase'
                    }))
                }
            })
        },
        // 产品交流
        toProductExchange(id) {
            this.$router.push({
                name: 'PostDetail', query: {
                    key: this.$UrlEncode.encode(JSON.stringify({
                        id: id,
                        type: 'ProductExchange'
                    }))
                }
            })
        },

        // MiscellaneousTalkList
        // 
        getIpAddress() {
            this.$CommonRequests.getIpAddress().then((res) => {
                if (res.code == 200) {
                    let { ip, province, city, districts } = res.data,
                        IpAddressInfo = {
                            fcommentLocation: province + '*' + city + '*' + districts,
                            sourceIp: ip
                        };
                    this.IpAddressInfo = IpAddressInfo;
                    this.$setStorage('getIpAddress', IpAddressInfo)
                }
            })
        },

    },
}

</script>
<style lang='less' scoped>
.Community {
    .carousel {
        width: 80%;

        img {
            width: 100%;
        }
    }

    .breadcrumb {
        padding: 10px 0;
        border-bottom: 1px solid #FBFBFB;
    }

    .el-breadcrumb ::v-deep .el-breadcrumb__inner {
        color: #666666 !important;
        font-weight: 400 !important;
        font-size: 14px;
    }

    .el-breadcrumb__item:last-child ::v-deep .el-breadcrumb__inner {
        color: #0363FA !important;
        font-weight: 400 !important;
        font-size: 14px;
    }

    .taskList {
        width: 1278px;
        height: 140px;

        img {
            width: calc(100% / 6 - 9px);
            cursor: pointer;
        }
    }

    .SupplyAndPurchaseList {

        .title {
            font-size: 36px;
            font-weight: 500;
            color: #333333;
            line-height: 60px;
        }

        .item {
            padding-top: 10px;
            width: calc(20% - 10px);
            height: 300px;
            background-color: #F6F8FB;
            border-radius: 10px;
            margin-left: 10px;
            margin-bottom: 5px;
            cursor: pointer;

            &:hover {
                transform: translateY(-3px);
            }

            img {
                margin-left: 12px;
                width: 225px;
                height: 170px;
                border-radius: 10px;
            }

            .postHead {
                margin-top: 15px;
                padding: 0 12px;
                font-size: 18px;
                font-weight: 500;
                color: #333333;
                line-height: 36px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            .previewContent {
                padding: 0 12px;
                font-size: 14px;
                font-weight: 400;
                color: #666666;
                line-height: 24px;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 3;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }

    .ProductExchange {

        .title {
            font-size: 36px;
            font-weight: 500;
            color: #333333;
            line-height: 60px;
        }

        .item {
            padding-top: 10px;
            width: calc(20% - 10px);
            height: 300px;
            background-color: #F6F8FB;
            border-radius: 10px;
            margin-left: 10px;
            margin-bottom: 5px;
            cursor: pointer;

            &:hover {
                transform: translateY(-3px);
            }

            img {
                margin-left: 12px;
                width: 225px;
                height: 170px;
                border-radius: 10px;
            }

            .postHead {
                margin-top: 15px;
                padding: 0 12px;
                font-size: 18px;
                font-weight: 500;
                color: #333333;
                line-height: 36px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            .previewContent {
                padding: 0 12px;
                font-size: 14px;
                font-weight: 400;
                color: #666666;
                line-height: 24px;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 3;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }
}




.CommunityIndex {
    flex-wrap: wrap;

    .carousel {
        width: 65%;

        img {
            width: 100%;
        }
    }

    @keyframes animate {
        0% {
            transform: translateY(0px)
        }

        20% {
            transform: translateY(-24px)
        }

        40%,
        100% {
            transform: translateY(0px)
        }
    }

    .community-ul {
        flex-wrap: wrap;

        li {
            list-style: none;
            width: 40%;
            border: 1px solid #333;
            border-radius: 30px;
            text-align: center;
            line-height: 40px;
        }

        li:hover {
            background-color: #f0f8ff;
            cursor: pointer;
            color: red;
        }
    }

    .title_bar {
        height: 43px;
        line-height: 40px;
        border-bottom: 1px solid #cae0f3;

        a {
            position: relative;
            display: inline-block;
            border-bottom: 3px solid #237ccf;
            text-decoration: none;
            font-size: 20px;
            font-weight: bold;
            color: transparent;
            letter-spacing: 2px;
            text-transform: uppercase;
            -webkit-text-stroke: 1px rgb(0 82 189);
        }


        a:hover {
            color: rgba(52, 134, 219, .8);
            ;
        }
    }




    .module1 {
        align-self: flex-start;
        width: 30%;
        height: 300px;
    }

    .module2 {
        width: 50%;
        margin-top: 30px;
        align-self: flex-start;
    }

    .module3 {
        align-self: flex-start;
        width: 48%;
        margin-top: 30px;
    }

    .box_ul {
        li {
            display: block;
            padding: 10px;

            a {
                display: block;

                span {
                    display: block;
                }
            }

            a:hover {
                color: #387eff;
            }
        }

    }
}
</style>
